.testimonials {
  padding: 130px 0 255px;

  @media (max-width: 1100px) {
    padding: 130px 0;
  }

  @media (max-width: 1100px) {
    padding: 132px 0 100px;
  }

  .container-testimonials {
    display: flex;
    position: relative;

    .text-testimonials {
      max-width: 477px;
      width: 42%;
      position: relative;

      @media (max-width: 560px) {
        width: 100%;
      }

      svg {
        position: absolute;
        left: -26.6px;
        top: -27.2px;

        @media (max-width: 560px) {
          left: calc(50% - 31px);
        }
      }

      .title {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 46px;
        color: #212E5E;
        margin-bottom: 51px;
        position: relative;

        @media (max-width: 1100px) {
          font-size: 26px;
        }

        @media (max-width: 768px) {
          font-size: 24px;
          text-align: center;
          line-height: 42px;
          margin-bottom: 40px;
        }
      }

      .author {
        position: relative;

        .name {
          font-family: 'Archivo';
          display: block;
          width: 100%;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 42px;
          color: #E75E24;
          margin-bottom: 7px;

          @media (max-width: 768px) {
            font-size: 24px;
            line-height: 42px;
            text-align: center;
            margin-bottom: 5px;
          }
        }

        .degree {
          display: block;
          width: 100%;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: #8D9BAA;

          @media (max-width: 768px) {
            font-size: 15px;
            line-height: 23px;
            text-align: center;
          }
        }
      }
    }

    .img-testimonial {
      margin-right: -80px;
      margin-left: auto;
      max-width: 630px;
      max-height: 722px;
      object-fit: cover;
      position: absolute;
      top: -280px;
      right: 0;
      width: 66%;

      @media (max-width: 1000px) {
        top: -200px;
      }

      @media(max-width:900px){
        margin-right: -30px;
        width: 60%;
      }

      @media (max-width: 768px) {
        top: -150px;
      }

      @media (max-width: 560px) {
        display: none;
      }
    }

    .vector {
      width: 182px;
      height: 300px;
      border: 1px solid #DAE2ED;
      border-left: 0;
      border-bottom: 0;
      position: absolute;
      right: 216px;
      top: calc(100% + 40px);

      @media (max-width: 1100px) {
        top: calc(50% + 16.8px);
      }

      @media (max-width: 768px) {
        display: none;
      }

      svg {
        &:first-child {
          position: absolute;
          top: -10px;
          left: -20px;
        }

        &:last-child {
          bottom: -20px;
          position: absolute;
          right: -10px;
        }
      }
    }
  }
}
